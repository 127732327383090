<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9795 24.375V24.3437C23.0995 22.147 21.5795 20.2701 19.6198 18.9601C17.6601 17.6501 15.3525 16.9683 13.0005 17.0045C10.6488 16.9681 8.34112 17.6497 6.38142 18.9594C4.42171 20.269 2.90178 22.1455 2.02148 24.3418V24.375M18.1109 6.77363C18.1104 7.79131 17.8104 8.78598 17.2485 9.63191C16.6867 10.4778 15.8884 11.137 14.9545 11.5261C14.0207 11.9152 12.9933 12.0167 12.0022 11.8179C11.0111 11.619 10.1007 11.1287 9.38624 10.4089C8.67181 9.68915 8.18543 8.77221 7.98845 7.77404C7.79148 6.77588 7.89281 5.7413 8.27967 4.80114C8.66654 3.86097 9.32145 3.05742 10.1618 2.49208C11.0021 1.92674 11.99 1.625 13.0006 1.625C13.6719 1.625 14.3365 1.75819 14.9567 2.01696C15.5769 2.27574 16.1404 2.65502 16.615 3.13314C17.0896 3.61127 17.4662 4.17888 17.7228 4.80352C17.9795 5.42817 18.1112 6.09762 18.1109 6.77363Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
